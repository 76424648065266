"use client";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { Spinner } from "shared-ui";

import "@/styles/globals.css";
import { GOOGLE_SITE_VERIFICATION } from "@/config/env";

export default function Page() {
  const router = useRouter();

  useEffect(() => {
    router.push("/id/step/account-info");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <html>
      <head>
        <meta
          name="google-site-verification"
          content={GOOGLE_SITE_VERIFICATION}
        />
      </head>
      <body>
        <div className="flex h-screen w-screen items-center justify-center">
          <Spinner size="large" color="primary" />
        </div>
      </body>
    </html>
  );
}
