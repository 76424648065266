export const ENV = process.env.NEXT_PUBLIC_ENV;

export const isDevelopment = ENV === "staging" || ENV === "development";

export const SITEKEY_STEP_ONE =
  process.env.NEXT_PUBLIC_TURNSTILE_SITEKEY_STEP_ONE || "";
export const SITEKEY_STEP_TWO =
  process.env.NEXT_PUBLIC_TURNSTILE_SITEKEY_STEP_TWO || "";

export const isPreProduction = ENV === "preproduction";
export const isProduction = ENV === "production";

export const GOOGLE_SITE_VERIFICATION =
  process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION;
